import { MdiSearch } from "components/miloDesignSystem/atoms/icons/MdiSearch";
import { TextField } from "components/miloDesignSystem/atoms/textField/TextField";
import { UserFilters } from "../UsersTab";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { cx, noop } from "utilities";
import styles from "../UsersTab.module.css";

interface Props {
  filters: UserFilters;
  setFilter: <T extends keyof UserFilters, U extends UserFilters[T]>(name: T, value: U) => void;
}

export const FiltersSection = ({ filters, setFilter }: Props) => {
  return (
    <div className="d-flex align-items-center justify-content-between py-1">
      <TextField
        containerClassName={cx(styles.usersSearchField, "w-100")}
        endIcon={<MdiSearch color="neutralBlack48" size="18" />}
        onChange={event => {
          setFilter("page", 1);
          setFilter("search", event.target.value);
        }}
        placeholder="Szukaj..."
        value={filters.search ?? ""}
      />
      <div className="d-flex align-items-center gap-1">
        <span className="divider line-divider" />
        <Button
          className="text-uppercase"
          disabled
          onClick={noop}
          size="small"
          startIcon={MdiAdd}
          variant="gray"
        >
          Dodaj użytkownika
        </Button>
      </div>
    </div>
  );
};
